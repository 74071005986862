import { type Dictionary } from './Order'

export enum FieldType {
  EUR = 'EUR',
  MW = 'MW',
  Area = 'Area',
  Int = 'Int',
  AreaOrNone = 'AreaOrNone',
  Select = 'Select',
  Tags = 'Tags',
  MultiProduct = 'MultiProduct',
  Bids = 'Bids'
}

export interface AlgorithmField {
  type: FieldType
  name: string
  nameUI: string
  value: any
}

export interface AlgorithmSelectField extends AlgorithmField {
  values: string[]
}

export interface AlgorithmState {
  name: string
  active: boolean
  fields: AlgorithmField[]
  external: boolean
}

/**
 * Convert list of fields to a dictionary for modification
 * @param fields
 */
export const fields2Dictionary = (fields: AlgorithmField[]) => {
  const dictionary: Dictionary<any> = {}
  fields.forEach((field) => (dictionary[field.name] = field.value))
  return dictionary
}
