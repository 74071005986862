import { observer } from "mobx-react";
import { useStores } from "../../../use-stores";
import { type FC, useState } from "react";
import { type Bid, type BidEntry } from "./BidEntry";
import BidTable from "./BidTable";
import { type Product } from "../../../classes/Product";
import { TZDate } from "@date-fns/tz";
import { addDays, subHours, startOfDay } from "date-fns";
import { type Portfolio } from "../../../classes/Portfolio";
import { type SpotPrice } from "../../../classes/SpotPrice";
import './product-modal.scss';

interface ModalProps {
  onChange: (updatedBids: BidEntry[]) => void;
  state: BidEntry[];
  setShowModal: (show: boolean) => void;
  showModal: boolean;
}

function getBiddingWindow(): { tomorrow: Date; endOfWindow: Date } {
  const timeZone = "Europe/Copenhagen";
  const now = new Date();
  const tzDate = new TZDate(now, timeZone);
  const tomorrow = startOfDay(addDays(tzDate, 1));
  const dayAfterTomorrow = addDays(tomorrow, 1);
  const endOfWindow = subHours(dayAfterTomorrow, 2);

  return { tomorrow, endOfWindow };
}

function filterProducts(products: Product[]): Product[] {
  const { tomorrow, endOfWindow } = getBiddingWindow();
  const timeZone = "Europe/Copenhagen";

  return products.filter((product) => {
    const productDeliveryDate = new TZDate(
      new Date(product.deliveryStart),
      timeZone
    );
    return productDeliveryDate >= tomorrow && productDeliveryDate <= endOfWindow;
  });
}


function generateBids(
  products: Product[],
  minChange: number,
  quantity: number,
  limit: number,
  getSpotPrice: (eicCode: string, deliveryStart: string) => SpotPrice | undefined,
  portfolio: Portfolio | undefined,
  bidEntryIndex: number
): Bid[] {
  return products.map((product) => {

    const eicCode = portfolio?.eic;
    let currentLimit = limit;

    if (eicCode) {
      const spotPrice = getSpotPrice(eicCode, product.deliveryStart);

      if (spotPrice && spotPrice.price !== undefined) {
        console.log(bidEntryIndex)
        currentLimit = spotPrice.price + (bidEntryIndex * -500)
      }
    }

    const bid: Bid = {
      minChange,
      quantity,
      limit: currentLimit,
    };
    return bid;
  });
}

export interface ColumnConfig {
  label: string;
  key: keyof Bid;
  modifiable?: boolean;
  multiplier?: number;
}

const ProductModal: FC<ModalProps> = observer(({ onChange, setShowModal, showModal, state }) => {
  const { configStore, spotStore } = useStores();
  const { getSpotPrice } = spotStore;
  const { products, getPortfolio } = configStore;
  const [bidEntryIndex, setBidEntryIndex] = useState<number>(0);

  // Initial default values for price, quantity, and limit
  const [minChange, setMinChange] = useState<number>(800);
  const [defaultQuantity, setDefaultQuantity] = useState<number>(0);
  const [defaultLimit, setDefaultLimit] = useState<number>(0);
  const filteredProducts = filterProducts(products);
  const [isViewing, setIsViewing] = useState<boolean>(false);
  const [tableData, setTableData] = useState<BidEntry[]>(() => {
    const initialBids = generateBids(
      filteredProducts,
      minChange,
      defaultQuantity,
      defaultLimit,
      getSpotPrice,
      getPortfolio,
      bidEntryIndex
    );

    return filteredProducts.map((product, index) => ({
      productId: product.name,
      deliveryStart: product.deliveryStart,
      contractId: product.contractId,
      bids: [initialBids[index]],
    }));
  });

  // Modify cell function
  const modifyCell = (
    productId: string,
    bidIndex: number,
    key: keyof Bid,
    value: number
  ) => {
    setTableData((prevData) => {

      // Spread the previous state
      const prev = [...prevData];
      const bidEntryIndex = prev.findIndex(
        (entry) => entry.productId === productId
      );
      const bidEntry = { ...prev[bidEntryIndex] };
      const bids = [...bidEntry.bids];
      const bid = { ...bids[bidIndex] };

      // Update the desired key in the bid
      bid[key] = value;
      bids[bidIndex] = bid;
      bidEntry.bids = bids;
      prev[bidEntryIndex] = bidEntry;

      return prev;
    });
  };

  // Handle saving the data
  function handleSaveClick() {
    onChange(tableData);
    setShowModal(false);
  }

  // Handle adding new bids
  function handleAddClick(newBidEntryIndex: number) {
    // Increase the aggressiveness of the default values
    const updatedMinChange = minChange + 500;
    const updatedQuantity = defaultQuantity;
    const updatedLimit = defaultLimit;

    // Update the state with new default values
    setMinChange(updatedMinChange);
    setDefaultQuantity(updatedQuantity);
    setDefaultLimit(updatedLimit);

    const newBids = generateBids(
      filteredProducts,
      updatedMinChange,
      updatedQuantity,
      updatedLimit,
      getSpotPrice,
      getPortfolio,
      newBidEntryIndex,
    );

    // Update the table data
    setTableData((prevData: BidEntry[]) => {
      return prevData.map((bidEntry, index) => ({
        ...bidEntry,
        bids: [...bidEntry.bids, newBids[index]],
      }));
    });
  }

  return (
    <div>
      {showModal && (
        <div className="modal">
          <div className="modal-main-content">
            <div className="modal-table">
              <BidTable data={tableData} modifyCell={modifyCell} isViewing={isViewing} />
              <div className="modal-navigation">
                <button
                  disabled={isViewing || bidEntryIndex === 2}
                  onClick={() => {
                    const newIndex = bidEntryIndex + 1;
                    setBidEntryIndex(newIndex);
                    handleAddClick(newIndex)
                  }}>Add</button>
                <button
                  disabled={isViewing}
                  onClick={handleSaveClick}
                >
                  Save
                </button>
                <button onClick={() => {
                  setShowModal(false)
                  setIsViewing(false)
                }}>Close</button>

              </div>
            </div>
          </div>
        </div>
      )}
      <div className='field-group bids-group'>
        <p>
          View
        </p>
        <button
          onClick={(e) => {
            if (state?.[0]?.bids?.length > 0) {
              setTableData(state);

              // Updating the bidEntryIndex to bidEntries from the state
              setBidEntryIndex(state[0].bids.length - 1);

              setIsViewing(true);
              setShowModal(true);
            }}
          }
        >
          View
        </button>
      </div>

    </div>
  );
});

export default ProductModal;
